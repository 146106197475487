/* .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
	@apply bg-black text-white;
} */
.ant-table-cell {
	text-align: center !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	text-align: center !important;
	vertical-align: middle !important;
}

:where(.css-dev-only-do-not-override-apn68).ant-select-outlined:not(
		.ant-select-customize-input
	)
	.ant-select-selector {
	background-color: var(--color-select-bg) !important;
	color: var(--color-text-primary) !important;
}

:where(.css-dev-only-do-not-override-apn68).ant-input-outlined {
	background-color: var(--color-select-bg) !important;
	color: var(--color-text-primary) !important;
}

.ant-input {
	background-color: var(--color-select-bg) !important;
	color: var(--color-text-primary) !important;
}

.ant-input::placeholder {
	color: #999; /* Customize the placeholder color here */
}

/* Select styles */
:where(.css-dev-only-do-not-override-apn68).ant-select-outlined:not(
		.ant-select-customize-input
	)
	.ant-select-selector {
	background-color: var(--color-select-bg) !important;
	color: var(--color-text-primary) !important;
}

/* AutoComplete styles */
& .ant-select-selector {
	background-color: var(--color-select-bg) !important;
	color: var(--color-text-primary) !important;
	/* border-color: var(--border-color) !important; */
}

.ant-select-dropdown {
	background-color: var(--color-select-bg) !important;
	color: var(--color-text-primary) !important;
}
.ant-select-item {
	color: var(--color-text-primary) !important;
}
& .ant-select-arrow {
	color: var(--color-text-primary) !important;
}

& .ant-select-selection-placeholder {
	color: var(--placeholder-color) !important;
}
.ant-picker-outlined {
	background-color: var(--color-select-bg) !important;
	color: var(--color-text-primary) !important;
}
/* DatePicker styles */
.ant-picker-input > input {
	background-color: var(--color-select-bg) !important;

	color: var(--color-text-primary) !important;
}
.ant-picker-input > input::placeholder {
	color: #999; /* Customize the placeholder color here */
}
.ant-picker .ant-picker-suffix {
	color: var(--color-text-primary) !important;
}

/* .ant-picker-cell {
	color: var(--color-text-primary) !important;
}

.ant-picker-dropdown {
	background-color: var(--color-select-bg) !important;

	color: var(--color-text-primary) !important;
} */
:where(.css-dev-only-do-not-override-apn68).ant-table-wrapper
	.ant-table-tbody
	> tr.ant-table-placeholder {
	background-color: var(--color-backgroundTable) !important;
	color: var(
		--color-text-primary
	) !important; /* Change this to your desired color */
}
:where(.css-dev-only-do-not-override-apn68).ant-table-wrapper
	.ant-table.ant-table-middle
	.ant-table-expanded-row-fixed {
	background-color: var(--color-backgroundTable) !important;
	color: var(
		--color-text-primary
	) !important; /* Change this to your desired color */
}
.ant-table-tbody {
	background-color: var(
		--color-backgroundTableHeader
	) !important; /* Change this to your desired color */
	color: var(
		--color-text-primary
	) !important; /* Change this to your desired color */
	font-size: 12px;
}

.custom-table .ant-table-thead > tr > th {
	background-color: var(
		--color-backgroundTableHeader
	) !important; /* Change this to your desired color */
	color: var(
		--color-text-primary
	) !important; /* Change this to your desired color */
	font-weight: bold !important; /* Optional: Make the text bold */
	font-size: 12px;
}
.css-dev-only-do-not-override-apn68.ant-table-wrapper .ant-table {
	background-color: var(--color-backgroundTable);
}

.ant-table-cell-row-hover {
	background-color: var(
		--color-backgroundTableHover
	) !important; /* Change this to your desired color */
}

:where(.css-dev-only-do-not-override-apn68).ant-btn-variant-outlined:disabled {
	background-color: var(
		--color-button-disabled
	); /* Change this to your desired color */
	color: #ffff; /* Change this to your desired color */
}
