@tailwind base;
@tailwind components;
@tailwind utilities;

.light {
	--color-background: #fffcfc; /* Light background */
	--color-backgroundTable: #f5f2f2; /* Light background */
	--color-backgroundTableHeader: #f7f7f7; /* Light background */
	--color-backgroundTableHover: #c2bfbf; /* Light background */
	--color-text-primary: #222222; /* Dark text for readability */
	--color-text-secondary: #7f8c8d; /* Lighter text for secondary content */
	--color-border: #e7e7e7; /* Light border for clean separation */
	--color-button-background: #3498db; /* Button background (blue) */
	--color-button-text: #ffffff; /* White text on buttons */
	--color-button-border: #3498db; /* Button border */
	--color-button-disabled: #7f8c8d; /* Disabled button text */
	--color-button-hover: #2f45c0; /* Button background on hover (blue) */
	--color-button-hover-text: #ffffff; /* White text on hovered buttons */
	--color-button-hover-border: #2f45c0; /* Button border on hover (blue) */
	--color-button-hover-disabled: #7f8c8d; /* Disabled button text on hover */
	--color-table-cell-border: #e7e7e7; /* Light border for clean separation */
	--color-table-cell-hover: #7e7e7e; /* Light background on hover */
	--color-table-cell-row-hover: #2f45c0; /* Button background on hover (blue) */
	--color-select-bg: #ffffff;
	--border-color: #d9d9d9;
	--placeholder-color: #999;
}

.noname {
	--color-background: #111111; /* Dark background */
	--color-backgroundTable: #0f073b; /* Light background */
	--color-backgroundTableHeader: #111111; /* Light background */
	--color-backgroundTableHover: #111111; /* Light background */
	--color-text-primary: #ecf0f1; /* Light text for readability */
	--color-text-secondary: #7f8c8d; /* Muted secondary text */
	--color-border: #212529; /* Darker borders */
	--color-button-background: #3498db; /* Button background (blue) */
	--color-button-text: #eee7e7; /* White text on buttons */
	--color-button-border: #3498db; /* Button border */
}
.dark {
	--color-background: #111111; /* Dark background */
	--color-backgroundTable: #0f073b; /* Light background */
	--color-backgroundTableHeader: #111111; /* Light background */
	--color-backgroundTableHover: #191a1f; /* Light background */
	--color-text-primary: #ecf0f1; /* Light text for readability */
	--color-text-secondary: #7f8c8d; /* Muted secondary text */
	--color-border: #212529; /* Darker borders */
	--color-button-background: #0a4d68; /* Button background (blue) */
	--color-button-text: #ffffff; /* White text on buttons */
	--color-button-border: #3498db; /* Button border */
	--color-button-disabled: #4e4e4e; /* Disabled button text */
	--color-button-hover: #2f45c0; /* Button background on hover (blue) */
	--color-button-hover-text: #ffffff; /* White text on hovered buttons */
	--color-button-hover-border: #2f45c0; /* Button border on hover (blue) */
	--color-button-hover-disabled: #4e4e4e; /* Disabled button text on hover */
	--color-table-cell-border: #4e4e4e; /* Subtle border for clean separation */
	--color-table-cell-hover: #444444; /* Dark hover effect */
	--color-table-cell-row-hover: #2f45c0; /* Highlighted row background */
	--color-select-bg: #000000;
	--border-color: #424242;
	--placeholder-color: #888;
}


/* .ant-select-selector {
  background-color: var(--color-background);
}
&& .ant-menu-item-selected {
  background-color: rgb(51, 51, 150) !important;
 }

 && .ant-menu-title-content {
  color: var(--color-text-primary)!important;
 }
 && .ant-menu-submenu-arrow {
  color: var(--color-text-primary)!important;
 }

 && .ant-menu-item {
   background-color: var(--color-background);
 }

 && .ant-menu-item:hover,
 && .ant-menu-submenu-title:hover {
   background-color: var(--color-background) !important;
 } */
