@media print {
	body,
	html {
		margin: 0 !important;
		padding: 0 !important;
	}
}

body {
	margin: 0;
	font-family:
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		'Open Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	/* color: light-dark(#333b3c, #efefec) !important;
	background-color: light-dark(#754a09, #223a2c) !important; */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
	background: #29d2f5;
}

/* Common styles */
