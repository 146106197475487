@media print {
	body,
	html {
		margin: 0 !important;
		padding: 0 !important;
	}
}

.title {
	font-weight: 400;
	font-size: 14px;
	color: rgb(0, 0, 0);
}
.title2 {
	font-weight: bold;
	font-size: 14px;
	color: rgb(0, 0, 0);
}
.topCard {
	display: flex;
	justify-content: center;
}
.topTitle {
	text-align: center;
	margin: 10px 0;
	font-weight: bold;
	font-size: 14px;
}
.betweenCard2 {
	border-bottom: 0.5px dashed gray;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 10px 0;
}
